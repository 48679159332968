<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Pago No. {{ detail.code }} <b></b></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container v-if="detail.internal_account != undefined">
          <v-card-title>
            <strong>Pago No: {{ detail.code }}</strong>
            <v-spacer></v-spacer>
            <v-btn
              class="mx-2"
              fab
              dark
              color="indigo"
              :loading="loadingPdf"
              @click="getPaymentPDF()"
            >
              <v-icon dark>mdi-printer</v-icon>
            </v-btn>
          </v-card-title>
          <v-card height="100%">
            <v-row>
              <v-col cols="12" md="4">
                <v-list three-line subheader>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Cuenta Origen</v-list-item-title>
                      <v-list-item-subtitle>{{
                        detail.internal_account.source_company.second_name
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Creado por</v-list-item-title>
                      <v-list-item-subtitle>{{
                        detail.created_by.username
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" md="4">
                <v-list three-line subheader>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Cuenta Destino</v-list-item-title>
                      <v-list-item-subtitle>{{
                        detail.internal_account.destination_company.second_name
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Fecha de creación</v-list-item-title>
                      <v-list-item-subtitle>{{
                        detail.created | date
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" md="4">
                <v-list three-line subheader>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Total Pago:</v-list-item-title>
                      <v-list-item-subtitle
                        >Q.{{ detail.amount | currency }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card>
          <br />
          <p class="text-left text--disabled">Detalle de abonos a cobros</p>
          <v-data-table
            :headers="headers"
            :items="detail.details"
            class="elevation-1"
            disable-pagination
            disable-sort
            hide-default-footer
          >
            <template v-slot:item.quantity="{ item }">
              <span>{{ item.quantity | currency }}</span>
            </template>
            <template v-slot:item.price="{ item }">
              <span>{{ item.price | currency }}</span>
            </template>
            <template v-slot:item.description="{ item }">
              <span>{{ item.description }} A {{ item.internal_billing.code }}</span>
            </template>
            <template v-slot:item.amount="{ item }">
              <span>{{ item.amount | currency }}</span>
            </template>
          </v-data-table>
          <br />
          <p class="text-left text--disabled">Detalle de pagos</p>
          <v-data-table
            :headers="headersPayment"
            :items="detail.payment_methods"
            class="elevation-1"
            disable-pagination
            disable-sort
            hide-default-footer
          >
            <template v-slot:item.amount="{ item }">
              <span>{{ item.amount | currency }}</span>
            </template>
          </v-data-table>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import requests from "@/services/requests";
import blobResponse from "@/utils/response.blob";
export default {
  name: "BillingForm",
  components: {
    // InternalAccountField: () => import("@/components/fields/AccountInternalField.vue"),
    // CompanyField: () => import("@/components/fields/CompanyField.vue"),
  },
  props: {
    dialog: { type: Boolean, required: true },
    paymentId: { type: String, required: true },
  },
  data() {
    return {
      loadingPdf: false,
      detail: {},
      headers: [
        { text: "Descripción", value: "description" },
        { text: "Monto", value: "amount" },
      ],
      headersPayment: [
        { text: "Tipo", value: "payment_type" },
        { text: "Documento", value: "document" },
        { text: "Monto", value: "amount" },
      ],
    };
  },
  methods: {
    getPaymentDetail() {
      requests
        .get("/api/bussiness-connector/internal-payment/" + this.paymentId)
        .then((response) => {
          this.detail = response.data;
        });
    },
    getPaymentPDF() {
      this.loadingPdf = true;
      requests
        .get("/api/pdf/internal-payment/" + this.paymentId)
        .then((response) => {
          if (response.status == 200) {
            blobResponse(response.data, "application/pdf");
          }
        })
        .catch((error) => {
          console.error("ERROR:", error);
          this.snackbarText = "Ha ocurrido un error";
          this.snackbarStatus = true;
        });
      this.loadingPdf = false;
    },
    close() {
      this.$emit("close");
    },
  },
  watch: {},
  mounted() {
    this.getPaymentDetail();
  },
};
</script>
